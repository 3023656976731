import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
    query {
        coaching: file(relativePath: { eq: "diploma-coaching.png" }) {
            childImageSharp {
                fluid(maxHeight: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        experience: file(relativePath: { eq: "ervaringscertificaat.png" }) {
            childImageSharp {
                fluid(maxHeight: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        
        birth: file(relativePath: { eq: "diploma-geboorte-in-kaart.png" }) {
            childImageSharp {
                fluid(maxHeight: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
  }
`

const IndexPage = ({ data }) => (
  <Layout pageInfo={{
    pageName: "index",
    pageTitle: " Welkom bij Alles Kids Kindercoaching!",
    bannerTitle: "website-header-04.png",
  }}>
    <Seo title="Home" />

    <Container>
      <section className="container" id="experiences">

        <Row>
          <Col>
            <p>
              <div className="block">
                Ieder kind zit weleens niet lekker in zijn of haar vel. Dit kan zich uiten in bijvoorbeeld boosheid,
                onzekerheid, verdriet of angst. Maar wat als deze periode langer duurt? Of heeft uw kind misschien
                indrukwekkende gebeurtenissen meegemaakt, bijvoorbeeld medische problemen, pestervaringen of het uit
                elkaar gaan van de ouders? Een kind kan dit soort ervaringen met zich mee dragen en moeite hebben om de
                gevoelens die hieruit volgen goed te uiten, waardoor hij zich niet fijn voelt. Uw kind kan onzeker
                worden, het gaat misschien minder goed op school of u loopt in de thuissituatie tegen problemen aan met
                zijn of haar gedrag. Dat is natuurlijk vervelend voor uw kind, maar ook voor uzelf! Gelukkig kunnen deze
                situaties meestal wel verbeterd worden door middel van kind- en gezinscoaching.
              </div>
            </p>

            <p>
              <div className="block">
                Kindercoaching is een laagdrempelige, kortdurende en praktische begeleiding voor kinderen en jeugdigen
                tot 18 jaar. De belevingswereld van het kind staat hierbij centraal. Er wordt op een positieve en
                oplossingsgerichte manier gewerkt aan onder meer het zelfvertrouwen en de zelfkennis van het kind.
                Natuurlijk speelt u als ouder of verzorger hierbij ook een grote rol! Ouders of verzorgers worden dan
                ook betrokken in het traject en krijgen handvaten aangereikt om hun kind verder te kunnen begeleiden
                naar volwassenheid.
              </div>
            </p>

            <p>
              <div className="block">
                Het is goed om u vooraf te realiseren dat coaching geen therapie is! Coaching is gericht op de toekomst
                en gaat uit van mogelijkheden, er wordt niet grondig ingegaan op een oorzaak van problemen en er wordt
                geen diagnose gesteld. Coaching kan ook plaatsvinden zonder verwijzing van de huisarts.
              </div>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 1 }}>
            <Img className="image-padding" fluid={data.coaching.childImageSharp.fluid} alt="Diploma - Kindercoaching" />
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 2 }}>
            <Img className="image-padding" fluid={data.experience.childImageSharp.fluid} alt="Ervaringscertificaat" />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 1 }}>
            <Img className="image-padding" fluid={data.birth.childImageSharp.fluid} alt="Diploma - Geboorte in Kaart" />
          </Col>
        </Row>

      </section>
    </Container>
  </Layout>
)

export default IndexPage
